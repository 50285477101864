
import { email, required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

import { QUIZ_VARIANTS } from '@/modules/tour/domain/tour';

import { AUTH_LOGIN_MAGIC_LINK_SENT } from '../helpers/auth-component-names';

export default {
  name: 'Login',
  validations: {
    email: {
      email,
      required,
    },
  },
  components: {
    EmailInput: () => import('@/components/core/email-input/email-input.vue'),
  },
  data() {
    return {
      invalidEmail: false,
      isLoading: false,
      isEmailNotExist: null,
      email: '',
    };
  },
  computed: {
    homeUrl() {
      const variantsMap = {
        [QUIZ_VARIANTS.DEFAULT]: 'home',
        [QUIZ_VARIANTS.PILATES_NEW_CHECKOUT]: 'homePilates',
        [QUIZ_VARIANTS.WALL_PILATES]: 'homeFitOver40',
        [QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER]: 'homePilates',
        [QUIZ_VARIANTS.PILATES_NOW]: 'homePilatesNow',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V1]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V2]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V3]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_BMI_STEP_V4]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GOAL_STEP]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V1]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V2]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LP_NO_FOOTER_V3]: 'homePilatesChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHAIR_CHALLENGE]: 'homePilatesChairChallenge',
        [QUIZ_VARIANTS.PILATES_28_DAY_FLAT_BELLY]: 'homePilatesFlatBelly',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES]: 'homePilatesChallengeSomatic',
        [QUIZ_VARIANTS.BODY_NUTRITION]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.HEALTH_NO_SUGAR_CHALLENGE]: 'homeNutrition28DayNoSugarChallenge',
        [QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.NUTRITION_MONTHLY_CORTISOL_CLEANSE]: 'homeNutritionMonthlyCortisolCleanse',
        [QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_META_CHANGES]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_HIGH_PROTEIN]: 'homeNutritionProtein',
        [QUIZ_VARIANTS.NUTRITION_KETO]: 'homeNutritionKeto',
        [QUIZ_VARIANTS.NUTRITION_KETO_MEAL]: 'homeNutritionKetoMeal',
        [QUIZ_VARIANTS.NUTRITION_KETO_AGE]: 'homeNutritionKetoAge',
        [QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY_AGE]: 'homeNutritionKetoWeeklyAge',
        [QUIZ_VARIANTS.NUTRITION_KETO_MEAL_WEEKLY]: 'homeNutritionKetoWeeklyMeal',
        [QUIZ_VARIANTS.FAST_WEIGHT_LOSS]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.MACRO_DIET]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.OPTIMAL_WEIGHT]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.WEIGHT_LOSS_OVER_40]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.WEIGHT_LOSS_WITHOUT_FASTING]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.HEALTH_FOOD_IMPACT]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.METABOLIC_RENEWAL]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.CHOLESTEROL_CONTROL]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.WEIGHT_LOSS_SECRET]: 'homeBodyNutrition',
        [QUIZ_VARIANTS.YOGA_MENOPAUSE_CHALLENGE]: 'homeMenopauseChallenge',
        [QUIZ_VARIANTS.SOMATIC_YOGA]: 'homeSomaticYoga',
        [QUIZ_VARIANTS.SOMATIC_EXERCISES]: 'homeSomaticExercises',
        [QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_US]: 'homeNutritionMonthlyGenericUS',
        [QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_OTHERS]: 'homeNutritionMonthlyGenericOthers',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_US]: 'homePilatesChallengeGenericUs',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_OTHERS]: 'homePilatesChallengeGenericOthers',
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_US]: 'homeYogaChallengeGenericUs',
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_OTHERS]: 'homeYogaChallengeGenericOthers',
      };
      return variantsMap[this.getQuizVariant] || variantsMap[QUIZ_VARIANTS.DEFAULT];
    },
  },
  mounted() {
    this.$store.dispatch('auth/resetAuthData');
  },
  methods: {
    ...mapActions('auth', ['checkIfUserExistAction', 'setUserLoginEmailAction', 'setAuthComponentName', 'loginAction']),
    emailHandler($event) {
      this.email = $event;
    },
    submitFormHandler() {
      this.isLoading = true;
      this.setUserLoginEmailAction(this.email);
      this.checkIfUserExistAction(this.email).then(({ exists }) => {
        if (!exists) {
          this.isEmailNotExist = true;
          this.isLoading = false;
        } else {
          this.loginAction({
            email: this.email,
            redirectUrl: this.$route.query.redirect_url,
            redirectPath: this.$route.query.redirect_path,
          }).then(() => {
            this.setAuthComponentName(AUTH_LOGIN_MAGIC_LINK_SENT);
            this.isLoading = false;
          });
        }
      });
    },
    logout() {
      window.location.href = this.$getUrl('home');
    },
  },
};
